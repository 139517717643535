import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 607.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,607.000000) scale(0.100000,-0.100000)">


<path d="M9224 4201 c-44 -9 -110 -28 -145 -42 -56 -21 -60 -24 -34 -26 295
-24 462 -95 628 -268 127 -132 217 -346 217 -514 0 -34 4 -61 9 -61 11 0 43
98 62 185 18 87 15 258 -5 330 -92 316 -380 472 -732 396z"/>
<path d="M9062 3865 c-68 -16 -90 -35 -41 -35 79 0 236 -58 316 -116 137 -100
240 -270 262 -434 l7 -55 17 54 c21 68 22 227 3 292 -45 148 -155 256 -301
294 -71 18 -189 18 -263 0z"/>
<path d="M1815 3521 c-93 -37 -192 -76 -220 -86 -27 -10 -65 -34 -82 -52 l-33
-34 0 -480 c0 -312 4 -487 10 -500 6 -11 21 -19 35 -19 33 0 464 166 488 188
47 42 47 43 47 545 0 446 -1 475 -18 490 -10 10 -27 17 -38 16 -10 0 -95 -31
-189 -68z m118 -104 c2 -7 -15 -168 -39 -357 -64 -509 -51 -451 -106 -477 -75
-36 -73 -39 -98 152 -5 39 -25 177 -44 309 -26 171 -33 243 -26 257 18 32 91
62 103 42 3 -5 15 -99 28 -211 13 -111 26 -197 30 -190 3 7 9 40 12 73 13 141
40 351 48 373 13 34 81 56 92 29z"/>
<path d="M2565 3561 c-38 -15 -139 -54 -224 -87 -169 -65 -204 -89 -214 -151
-4 -21 -7 -238 -7 -483 0 -505 -1 -498 69 -486 31 5 320 114 435 163 78 34 76
18 76 556 0 311 -4 485 -10 498 -14 25 -41 23 -125 -10z m-7 -198 c-3 -56 -4
-59 -40 -76 l-38 -19 0 -319 c0 -181 -4 -328 -9 -338 -12 -22 -85 -55 -105
-48 -14 6 -16 46 -16 326 l0 321 -46 -7 -45 -6 3 59 c3 56 5 60 38 76 64 32
215 87 238 88 22 0 23 -3 20 -57z"/>
<path d="M3230 3570 c-88 -37 -328 -130 -334 -130 -3 0 -25 -9 -49 -20 -80
-38 -77 -17 -77 -555 0 -309 4 -483 10 -496 15 -28 44 -24 166 22 60 23 136
52 169 64 126 47 171 67 189 83 45 40 46 56 46 544 0 403 -2 466 -16 486 -18
26 -44 27 -104 2z m-25 -500 l0 -346 -26 -49 c-36 -71 -97 -115 -157 -115 -62
1 -92 24 -103 81 -11 61 -11 632 1 663 6 17 22 29 49 37 74 22 71 38 71 -321
0 -188 4 -320 9 -320 41 0 41 -3 41 339 0 247 3 331 13 343 19 24 49 38 77 35
l25 -2 0 -345z"/>
<path d="M7280 3583 c-69 -25 -428 -168 -441 -176 -9 -6 -24 -23 -33 -38 -14
-24 -16 -83 -16 -507 0 -264 3 -487 6 -496 11 -29 67 -17 249 53 292 112 295
114 315 166 6 16 10 207 10 500 0 417 -2 475 -16 489 -15 16 -45 19 -74 9z
m-73 -215 c19 -19 26 -39 30 -85 7 -72 -5 -98 -53 -118 -49 -20 -62 -11 -66
43 -3 54 -24 66 -57 33 -15 -15 -21 -34 -21 -67 0 -49 5 -56 112 -149 86 -75
114 -177 80 -292 -28 -95 -118 -173 -200 -173 -82 0 -112 39 -112 145 0 71 16
99 65 111 45 11 55 0 55 -61 0 -56 0 -56 28 -53 20 2 32 13 46 41 29 60 12
101 -75 179 -39 34 -82 82 -96 106 -23 38 -25 51 -21 117 8 118 63 208 150
245 39 17 107 5 135 -22z"/>
<path d="M7800 3535 c-74 -29 -174 -68 -222 -86 -53 -19 -98 -44 -115 -61
l-28 -29 -3 -485 c-2 -328 1 -491 8 -504 18 -34 64 -23 300 68 201 78 227 90
247 121 l23 34 0 486 c0 366 -3 490 -12 499 -21 21 -59 13 -198 -43z m70 -457
c0 -323 -2 -346 -21 -388 -37 -82 -98 -130 -164 -130 -47 0 -71 14 -90 50 -12
24 -15 84 -15 354 0 178 3 332 6 341 8 20 56 45 89 45 l25 0 0 -325 c0 -306 1
-325 18 -325 41 0 42 11 42 352 l0 328 33 20 c17 11 42 20 55 20 l22 0 0 -342z"/>
<path d="M8495 3555 c-44 -18 -147 -59 -228 -90 -127 -49 -152 -62 -168 -89
-18 -29 -19 -57 -19 -522 0 -534 -3 -504 52 -504 24 0 431 154 466 176 13 8
32 29 42 47 19 31 20 54 20 507 -1 379 -3 479 -14 493 -19 25 -59 20 -151 -18z
m-9 -188 c25 -22 27 -30 32 -125 5 -106 -8 -181 -35 -203 -11 -10 -11 -15 6
-30 25 -24 35 -89 29 -198 -6 -93 -26 -147 -70 -189 -30 -28 -186 -95 -205
-88 -10 4 -13 79 -13 388 0 211 4 388 8 395 10 16 160 72 194 72 15 1 39 -9
54 -22z"/>
<path d="M8363 3243 c-9 -3 -13 -35 -13 -110 l0 -105 23 15 c20 13 22 22 22
107 0 91 -5 104 -32 93z"/>
<path d="M8350 2810 c0 -61 4 -110 9 -110 5 0 17 7 25 16 12 12 16 37 16 110
0 94 0 94 -25 94 l-25 0 0 -110z"/>
<path d="M9005 3504 c-181 -70 -226 -91 -252 -119 l-33 -35 0 -488 c0 -366 3
-491 12 -500 23 -23 66 -12 265 64 253 97 262 101 284 138 18 29 19 59 19 517
0 364 -3 488 -12 497 -21 21 -60 11 -283 -74z m138 -144 c24 -27 27 -38 27
-99 l0 -69 -46 -22 c-53 -26 -63 -21 -71 40 -3 24 -12 44 -21 48 -24 9 -62
-43 -62 -86 0 -32 9 -45 93 -127 107 -103 125 -142 113 -242 -17 -144 -106
-244 -218 -243 -46 1 -64 10 -91 50 -17 25 -23 111 -11 158 7 26 51 52 89 52
24 0 25 -3 25 -54 0 -59 8 -71 45 -62 29 7 50 63 41 110 -6 32 -30 60 -120
144 -60 56 -86 108 -86 171 0 56 26 135 61 186 59 86 175 109 232 45z"/>
<path d="M1645 2078 c-56 -15 -135 -68 -174 -117 -73 -91 -92 -224 -49 -338
26 -69 105 -154 175 -186 l48 -22 3755 0 3755 0 57 27 c70 33 133 96 167 168
37 79 37 201 0 280 -33 70 -98 135 -169 169 l-55 26 -3735 2 c-2572 1 -3747
-2 -3775 -9z m3769 -115 c8 -21 -17 -48 -38 -40 -9 4 -16 18 -16 32 0 20 5 25
24 25 13 0 27 -8 30 -17z m2213 1 c8 -20 -21 -49 -41 -41 -9 4 -16 18 -16 32
0 20 5 25 25 25 14 0 28 -7 32 -16z m-520 -96 c81 -4 84 -28 4 -28 l-61 0 0
-77 c0 -57 4 -81 17 -95 18 -20 54 -23 83 -8 26 14 39 -9 15 -27 -32 -23 -102
-17 -130 12 -23 22 -25 32 -25 110 l0 85 -40 0 c-29 0 -40 4 -40 15 0 11 11
15 40 15 39 0 40 1 40 35 0 22 6 37 18 44 15 9 17 4 20 -34 l3 -44 56 -3z
m-4930 -19 c34 -23 14 -44 -23 -24 -43 22 -88 19 -121 -9 -38 -33 -40 -102 -4
-140 28 -30 94 -36 127 -11 24 18 44 19 44 1 0 -35 -125 -60 -173 -35 -87 47
-88 176 -2 228 26 15 125 9 152 -10z m531 1 c67 -41 78 -131 22 -193 -26 -30
-33 -32 -93 -32 -55 0 -69 4 -91 24 -32 30 -50 82 -41 121 8 36 41 79 69 90
38 16 100 11 134 -10z m378 5 c5 -13 8 -13 21 0 18 19 67 19 85 1 12 -11 17
-11 33 0 23 17 67 18 83 2 19 -19 17 -238 -2 -238 -11 0 -15 23 -18 104 -1 57
-6 108 -11 112 -4 4 -20 3 -35 -2 -26 -9 -27 -11 -32 -109 -4 -80 -8 -100 -20
-100 -12 0 -16 21 -20 105 -5 98 -6 105 -26 108 -42 6 -54 -21 -54 -124 0 -86
-2 -95 -17 -92 -16 3 -18 18 -21 126 -2 105 0 122 13 122 9 0 18 -7 21 -15z
m557 3 c4 -10 7 -10 18 0 18 17 78 15 85 -3 5 -13 8 -13 27 0 33 23 74 19 92
-9 24 -35 18 -219 -7 -224 -15 -3 -18 9 -20 105 -3 101 -4 108 -24 111 -42 6
-54 -21 -54 -124 0 -86 -2 -95 -17 -92 -15 3 -19 19 -23 108 -5 96 -7 105 -25
108 -42 5 -50 -12 -53 -118 -3 -88 -6 -101 -20 -98 -15 3 -17 20 -18 126 -2
112 0 122 16 122 10 0 20 -6 23 -12z m577 -86 c0 -63 4 -102 12 -110 21 -21
83 -14 111 12 24 22 26 30 25 107 0 76 2 84 18 84 17 0 19 -11 22 -122 2 -103
0 -123 -12 -123 -8 0 -19 7 -24 16 -9 16 -11 16 -33 0 -33 -23 -100 -21 -133
5 -25 20 -26 24 -26 125 0 97 1 104 20 104 19 0 20 -7 20 -98z m550 77 l0 -20
26 20 c18 14 41 21 69 21 28 0 51 -7 69 -21 25 -20 26 -24 26 -125 0 -95 -2
-105 -17 -102 -15 3 -19 18 -23 101 -6 111 -12 121 -75 113 -61 -7 -75 -32
-75 -133 0 -75 -2 -84 -17 -81 -16 3 -18 18 -21 126 -2 117 -2 122 18 122 14
0 20 -7 20 -21z m642 -81 c3 -102 3 -103 31 -112 19 -7 37 -7 57 1 36 14 53
-3 25 -24 -32 -23 -102 -17 -130 12 -23 22 -25 32 -25 110 l0 85 -49 0 c-47 0
-68 9 -54 24 3 3 37 6 74 6 l68 0 3 -102z m628 84 c32 -26 18 -45 -18 -26 -62
32 -128 9 -146 -53 -9 -30 9 -92 31 -109 24 -18 93 -17 117 1 24 18 46 19 46
2 0 -40 -113 -61 -175 -31 -88 41 -81 187 10 224 37 16 111 11 135 -8z m557
-5 c23 -23 36 -217 15 -225 -7 -2 -19 4 -26 13 -12 17 -15 17 -37 2 -14 -10
-43 -17 -72 -17 -81 0 -123 65 -78 121 16 20 28 24 78 24 32 0 68 -4 81 -8 20
-8 22 -5 22 22 0 44 -36 65 -98 58 -26 -2 -55 -8 -64 -12 -13 -5 -18 -2 -18 9
0 25 38 36 112 33 49 -2 73 -8 85 -20z m1023 -69 c0 -71 4 -95 17 -110 18 -20
54 -23 83 -8 26 14 39 -9 15 -27 -32 -23 -102 -17 -130 12 -23 22 -25 32 -25
110 l0 85 -50 0 c-38 0 -50 4 -50 15 0 12 14 15 70 15 l70 0 0 -92z m614 73
c39 -26 55 -57 55 -106 0 -76 -50 -125 -129 -125 -83 0 -130 47 -130 128 1 73
53 122 130 122 28 0 56 -8 74 -19z m406 4 c0 -19 9 -19 34 0 29 22 102 19 126
-5 27 -27 31 -52 28 -145 -2 -65 -6 -80 -20 -83 -14 -3 -17 10 -20 98 -2 76
-7 103 -18 110 -26 16 -76 11 -104 -11 -25 -19 -26 -25 -26 -110 0 -81 -2 -90
-17 -87 -16 3 -18 18 -21 126 -2 117 -2 122 18 122 11 0 20 -7 20 -15z"/>
<path d="M2581 1823 c-44 -37 -43 -126 1 -158 50 -34 130 -5 141 51 7 38 -10
91 -36 109 -29 20 -80 19 -106 -2z"/>
<path d="M6437 1722 c-35 -39 -14 -72 46 -72 46 0 97 27 97 51 0 36 -115 53
-143 21z"/>
<path d="M8103 1825 c-42 -29 -42 -116 -1 -155 25 -23 84 -27 109 -7 52 40 49
131 -5 169 -19 13 -81 9 -103 -7z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
